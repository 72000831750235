import React from 'react';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import { useKLHInfo } from '../../content/projects/klh.project';
import { PageComponent } from '../../types/gatsby.types';
// import './ProjectPageKLH.scss';

type ProjectPageKLHProps = {}

const ProjectPageKLH: PageComponent<ProjectPageKLHProps> = props => {
  const metaInfo = useKLHInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
  >
    <SimpleEntryGallery entry={metaInfo} autoAddMobileSlides />
  </ProjectPage>
}

export default ProjectPageKLH;